<template>
  <b-card class="user-support">
    <b-row>
      <div class="mx-auto order-filters-position col-md-4">
        <b-form-group :label="$t('Filters')">
          <b-form-select
            v-model="selectedTicketType"
            :options="[
              {
                text: 'Open Tickets',
                value: '0',
              },
              {
                text: 'Close Tickets',
                value: '1',
              },
            ]"
          />
        </b-form-group>
      </div>
      <b-col
        cols="12"
      >
        <good-data-table
          ref="supportRef"
          :button-label="$t('Create New Tickets')"
          :columns="ticketColumns"
          :total-column="'response.responseData.total'"
          :data-column="'response.responseData.data'"
          :api-endpoint="apiEndpoint()"
          :extra-params="extraParams"
          @add-new-info="showAddModal"
        />
      </b-col>
    </b-row>
    <b-modal
      v-model="showTicketModal"
      :no-close-on-backdrop="true"
      :title="'Create Tickets'"
      size="lg"
      ok-only
      :ok-title="$t('Save Changes')"
      @hidden="showTicketModal = false"
      @ok="saveData"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Category')">
            <b-form-select
              v-model="dataInfo.admin_service"
              :options="[
                {
                  text: 'Choose Category',
                  value: '',
                },
                {
                  text: 'Order',
                  value: 'ORDER',
                },
                {
                  text: 'Service',
                  value: 'SERVICE',
                },
              ]"
              @change="changeSubCategory"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Sub Category')">
            <b-form-select
              v-model="dataInfo.ticket_category"
              :options="getSubCategory"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group :label="$t('Title')">
            <b-form-input
              v-model="dataInfo.title"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group :label="$t('Message')">
            <b-form-input
              v-model="dataInfo.description"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Picture')">
            <image-input
              :default-image="dataInfo.picture"
              @input-file="inputFileData"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      v-model="viewTicketModal"
      :no-close-on-backdrop="true"
      :title="'Tickets Details'"
      size="lg"
      hide-footer
      @hidden="viewTicketModal = false"
    >
      <b-row>
        <div class="col-md-5">
          <ul>
            <li>{{ $t('Ticket No') }}: {{ ticketInfo.ticket_id }}</li>
            <li>{{ $t('Title') }}: {{ ticketInfo.title }}</li>
            <li>{{ $t('Status') }}: {{ ticketInfo.status === 0 ? 'Open' : 'Closed' }}</li>
          </ul>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group :label="$t('Message')">
              <b-form-input
                v-model="viewTicket.description"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group :label="$t('Picture')">
              <image-input
                :default-image="viewTicket.picture"
                @input-file="inputFile"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
            class="d-flex justify-content-between align-items-center"
          >
            <b-button
              variant="primary"
              @click="addTicketMessage"
            >
              {{ $t('Submit') }}
            </b-button>
            <b-button
              variant="danger"
              @click="closeTicket"
            >
              {{ $t('Close Ticket') }}
            </b-button>
          </b-col>
        </div>
        <div class="col-md-7">
          <p class="text-center">
            {{ $t('Comments') }}
          </p>
          <chat-list :chat-messaged="ticketsComments" />
        </div>
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormGroup, BFormInput, BFormSelect, BButton, BModal } from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import ImageInput from './shared/ImageInput.vue'
import ChatList from '../shared/chat/ChatList.vue'
import { useUserUi } from './useUser'

export default {
  components: {
    BCard,
    BModal,
    BRow,
    BCol,
    BButton,
    ImageInput,
    GoodDataTable,
    BFormGroup,
    BFormInput,
    BFormSelect,
    ChatList,
  },
  data() {
    return {
      ticketColumns: [
        {
          label: 'Ticket Id',
          field: 'ticket_id',
        },
        {
          label: 'Name',
          field: 'title',
        },
        {
          label: 'Created Date',
          field: 'updated_at',
        },
        {
          label: 'Status',
          field: 'ticket_id',
          rField: 'ticket_id_text',
          useRenderer: true,
          align: 'center',
          useResolver: true,
          renderer: props => (props.status === 0 ? 'Open' : 'Closed'),
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('view'),
              text: 'View',
              action: props => {
                this.viewTickets(props)
              },
            },
          ],
        },
      ],
      extraParams: 'type=USER&status=0',
      showTicketModal: false,
      viewTicketModal: false,
      ticketInfo: {},
      subCategory: [],
      activeTicketInfo: {},
      viewTicket: {
        description: '',
        picture: '',
        id: '',
      },
      selectedTicketType: '0',
      dataInfo: {
        picture: null,
        _token: '',
        admin_service: '',
        type: 'USER',
        ticket_category: '',
        title: '',
        description: '',
      },
      extraFilterInput: 'status=0',
    }
  },
  computed: {
    getSubCategory() {
      return [
        ...this.subCategory
          .filter(x => x.status)
          .map(x => ({
            text: x.name,
            value: x.id,
          })),
      ]
    },
    ticketsComments() {
      if (!this.ticketInfo.ticket_comments) return []
      const userComments = []
      this.ticketInfo.ticket_comments.forEach((item, index) => {
        let username = ''
        let avatar = ''
        let id = ''
        if (item.type === 'USER' && this.ticketInfo.user) {
          username = this.ticketInfo.user.email
          avatar = this.$helpers.imageHelper(this.ticketInfo.user.picture)
          id = this.ticketInfo.user.id
        } else if (item.type === 'ADMIN') {
          username = 'Admin Support'
          // eslint-disable-next-line global-require
          avatar = require('@/assets/images/illustration/sales.svg')
          id = Math.random() * 10000
        }
        userComments.push({
          from: {
            name: username,
            avatar,
            id,
            userType: item.type === 'USER' ? 'NORMAL' : item.type,
          },
          uniqueId: index,
          message: item.comment,
          picture: item.picture,
          time: new Date(item.created_date),
          fileLink: this.$helpers.imageHelper(item.picture),
        })
      })
      return userComments
    },
  },
  watch: {
    selectedTicketType: {
      handler(currentValue) {
        if (currentValue === '0') {
          this.extraParams = 'type=USER&status=0'
        } else if (currentValue === '1') {
          this.extraParams = 'type=USER'
        }
        this.$refs.supportRef.loadItems(this.extraParams, this.apiEndpoint())
      },
    },
  },
  methods: {
    apiEndpoint() {
      // eslint-disable-next-line eqeqeq
      return this.selectedTicketType == 0 ? '/user/my_tickets' : '/user/closed_tickets'
    },
    scrollDown() {
      setTimeout(() => {
        const element = document.getElementById('chat__body')
        element.scrollTop = element.scrollHeight
      }, 1000)
    },
    viewTickets(payload, showTicketModal = true) {
      const { getTicketInfo } = useUserUi()
      this.activeTicketInfo = payload
      showLoader()
      getTicketInfo(payload.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.ticketInfo = data.responseData
            if (showTicketModal) {
              this.viewTicketModal = true
              this.scrollDown()
            }
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    inputFileData(payload) {
      if (this.dataInfo) this.dataInfo.picture = payload
    },
    inputFile(payload) {
      if (this.viewTicket) this.viewTicket.picture = payload
    },
    showAddModal() {
      this.showTicketModal = true
      Object.keys(this.dataInfo).forEach(item => {
        this.dataInfo[item] = ''
      })
      this.setToken()
    },
    changeSubCategory() {
      if (!this.dataInfo.admin_service) {
        return
      }
      this.dataInfo.ticket_category = ''
      const { getTicketSubCategory } = useUserUi()
      showLoader()
      getTicketSubCategory(this.dataInfo.admin_service)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.subCategory = data.responseData
            this.subCategory.push({
              status: 1,
              name: 'Other',
              value: 'OTHER',
            })
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    saveData() {
      showLoader()
      const formData = new FormData()
      Object.keys(this.dataInfo).forEach(item => {
        formData.append(item, this.dataInfo[item])
      })
      const { createTicket } = useUserUi()
      createTicket(formData)
        .then(({ data }) => {
          hideLoader()
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
            this.$refs.supportRef.loadItems(this.extraParams, this.apiEndpoint())
          } else {
            this.showTicketModal = true
            showDangerNotification(this, data.message)
          }
        })
        .catch(error => {
          this.showTicketModal = true
          showErrorNotification(this, error)
        })
    },
    closeTicket() {
      const { updateTicket } = useUserUi()
      showLoader()
      updateTicket({
        key: this.ticketInfo.id,
        status: 0,
      })
        .then(({ data }) => {
          hideLoader()
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
            this.viewTicketModal = false
            this.$refs.supportRef.loadItems(this.extraParams, this.apiEndpoint())
          } else {
            this.viewTicketModal = true
            showDangerNotification(this, data.message)
          }
        })
        .catch(error => {
          this.viewTicketModal = true
          showErrorNotification(this, error)
        })
    },
    addTicketMessage() {
      const { addTicketComment } = useUserUi()
      showLoader()
      this.setToken()
      const formData = new FormData()
      this.viewTicket.id = this.ticketInfo.id
      Object.keys(this.viewTicket).forEach(item => {
        formData.append(item, this.viewTicket[item])
      })
      addTicketComment(formData)
        .then(({ data }) => {
          hideLoader()
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
            this.viewTicket.description = ''
            this.viewTicket.picture = ''
            this.viewTickets(this.activeTicketInfo, false)
            this.scrollDown()
          } else {
            this.viewTicketModal = true
            showDangerNotification(this, data.message)
          }
        })
        .catch(error => {
          this.viewTicketModal = true
          showErrorNotification(this, error)
        })
    },
    setToken() {
      this.dataInfo.type = 'USER'
      // eslint-disable-next-line no-underscore-dangle
      this.dataInfo._token = 'whOGKrzZvsTxRS32iSfod8IpFWVAxNnmQr3Fzi5b'

      this.viewTicket.type = 'USER'
      // eslint-disable-next-line no-underscore-dangle
      this.viewTicket._token = 'whOGKrzZvsTxRS32iSfod8IpFWVAxNnmQr3Fzi5b'
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

.user-support .order-filters-position {
  position: absolute;
  z-index: 9;
  left: 150px;
  top: 20px;
}
</style>

<style scoped>
@media only screen and (max-width: 767px) {
  .user-support .order-filters-position {
    position: static;
  }
}
</style>
